/* MessageBox text */
.rce-mbox {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .rce-mbox {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.rce-mbox .rce-mbox-text {
  font-size: 0.8rem;
  font-family: "Montserrat";
  font-weight: 600;
}

@media (max-width: 767px) {
  .rce-mbox .rce-mbox-text {
    font-size: 0.6rem; /* Smaller font size for mobile */
  }
}

.rce-mbox-title {
  font-size: 1rem;
  font-weight: bold;
}

/* Font size for mobile screens (less than 800px) */
@media (max-width: 800px) {
  .rce-mbox-title {
    font-size: 0.8rem; /* Smaller font size for mobile */
  }
}
